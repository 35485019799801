.dishes {
  background-color: #ffff;
  padding: 1rem 2rem;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px 0px #e5e5e580;
  min-width: 332px;
  &__container {
    margin-top: 3rem;
  }
}
