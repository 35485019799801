.menu-page-container-list {
  background-color: #ffff;
  padding: 1rem 0.75rem 0.75rem 0.75rem;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px 0px #e5e5e580;
  min-width: 306px;
  min-height: 306px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &--heading {
    min-width: 20rem;
    width: 100%;
  }
  &--items {
    margin-top: 3rem;
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
