.dish-img-small {
    width: 110px;
    height: 110px;
}

.dish-title-new {
    font-size: 16px;
    /* flex-wrap: nowrap; */
    flex-grow: 1;
    /* width: 200px; */
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dish-description {
    display: -webkit-box;
    /* max-width: 260px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 300;
    font-size: 11px;
    color: #3C3C43;
    height: 32px;
    margin: 4px auto 8px 0px;
    /* width: 100%; */
}

.input-dish {
    width: 80%;
    height: 40px;
    margin: 16px auto;
    border: none;
    padding-left: 15px;
    border-radius: 5px;
}

/* .quantity-button {
    width: 12px;
    font-size: 10px;
    height: 12px;
    border-radius: 4px;
    padding: 4px;
    background-color: #3f51b5;
    text-align: center;
    margin: auto 4px;
    cursor: pointer;
} */
.round-corners{
    border-radius: 14px 14px 0px 0px;
}

.dish-display-paper{
    max-height:550px;
    min-height: 450px;
}

.text-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}