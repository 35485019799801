.dish_card {
  min-width: 292px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
  padding: 1rem;
  & > * {
    margin: 0.75rem 0;
  }
  &-first {
    display: flex;
    margin-top: 0;
    justify-content: space-between;
    &--left {
      margin-left: 0.5rem;
    }
    &--right {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 7.2rem;
      width: 15.2rem;
      img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-second {
    margin-left: 0.5rem;
    &--description {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 1.35rem;
      line-height: 24px;
      color: #3c3c43;
      flex-wrap: wrap;
    }
    &--pictorial {
      margin-left: 0.5rem;
      &-container {
        display: flex;
        margin-top: 0.5rem;
      }
    }
    &--mark {
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }
}

.bestseller {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ebff00;
}

.dishname {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  & > * {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #3c3c43;
  }
}

.variants {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3c3c43;
  margin-left: 2px;
}
