.container {
  font-weight: 700;
  background: white;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 500px;
  overflow-y: auto;
  padding: 1.5rem 2rem;
  .form-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    .fields-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      position: relative;
      &-textarea {
        width: 60%;
        textarea{
          width: 100%;
          outline: none;
          border-radius: 5px;
          border: 1px solid #e4e4e4;
          background-color: #f6f7fa;
          resize: none;
          padding: 0.5rem;
        }
        input {
          width: 100%;
          outline: none;
          border-radius: 5px;
          border: 1px solid #e4e4e4;
          background-color: #f6f7fa;
          resize: none;
          padding: 0.5rem;
        }
        select {
          width: 100%;
          outline: none;
          border-radius: 5px;
          border: 1px solid #e4e4e4;
          background-color: #f6f7fa;
          resize: none;
          padding: 0.5rem;
        }
         
        &--required {
          color: red;
        }
      }
      &-input {
        width: 60%;
        input {
          width: 100%;
          outline: none;
          border-radius: 5px;
          border: 1px solid #e4e4e4;
          background-color: #f6f7fa;
          resize: none;
          padding: 0.5rem;
        }
        &--required {
          color: red;
          margin-top: 0.25rem;
        }
      }
    }
  }
}

.input {
  display: none;
}
.upload-custom-btn {
  display: inline-block;
  padding: 1rem 1.75rem;
  border-radius: 3px;
  width: 60%;
  text-align: center;
  background-color: #0094ff;
  color: #f6f7fa;
  cursor: pointer;
}

.pictorial {
  margin-bottom: 1rem;
  &__container {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;
    & > *:not(:last-child) {
      margin-right: 0.75rem;
    }
    &--item {
      &-label {
        user-select: none;
        input {
          display: none;
        }
        span {
          padding: 0.5rem 2.5rem;
          font-family: sans-serif;
          background: #fbfbff;
          border-radius: 1rem;
          border: 1px solid #e5e5e5;
        }
        input:checked + span {
          border-radius: 1rem;
          background: #0094ff;
          color: #fff;
        }
      }
    }
  }
}

.variants {
  & > *:not(:last-child) {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
  }
  &__item {
    display: flex;
    align-items: center;
    &-input {
      margin-top: 0.5rem !important;
      cursor: pointer;
    }
    &-label {
      margin-left: 0.5rem;
      margin-top: 0.5rem !important;
    }
  }
}
